define("frontend/models/offered-service", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    price: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0
    })],
    duration_in_minutes: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0
    })],
    incremental_price: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0
    })]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    duration_in_minutes: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    latest_price: _emberData.default.attr('number'),
    latest_incremental_price: _emberData.default.attr('number'),
    capacity: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    company: _emberData.default.belongsTo('company'),
    company_name: _emberData.default.attr('string'),
    company_time_zone: _emberData.default.attr('string'),
    company_sales_tax: _emberData.default.attr('string'),
    company_country: _emberData.default.attr('string'),
    apply_sales_tax: _emberData.default.attr('boolean'),
    company_state: _emberData.default.attr('string'),
    color_hex: _emberData.default.attr(),
    incremental_price: _emberData.default.attr('number'),
    position: _emberData.default.attr('number'),
    effectiveDate: _emberData.default.attr(),
    price_start_date: _emberData.default.attr(),
    price_end_date: _emberData.default.attr(),
    all_day_service: _emberData.default.attr('boolean'),
    is_add_on: _emberData.default.attr('boolean'),
    show_to_pet_parents: _emberData.default.attr('boolean'),
    has_any_appointments: _emberData.default.attr('boolean'),
    service_category_id: _emberData.default.attr(),
    service_category: _emberData.default.belongsTo('service-category'),
    service_category_position: _emberData.default.attr(),
    capacity_type: _emberData.default.attr(),
    capacity_cap: _emberData.default.attr(),
    new_start_time: _emberData.default.attr(),
    duration: computed('duration_in_minutes', function () {
      var duration = this.get('duration_in_minutes');

      if (duration < 30 || !duration) {
        duration = 30;
      }

      var heightCalc = duration / 60.0 * 128 - 3;
      return heightCalc;
    }),
    durationDisplay: computed('duration_in_minutes', 'all_day_service', function () {
      if (this.get('all_day_service')) {
        return 'all-day';
      }

      var duration = this.get('duration_in_minutes');
      var hours = Math.floor(duration / 60);
      var minutes = duration % 60;

      if (hours == 0) {
        return "".concat(minutes, " min");
      } else {
        var display = "".concat(hours).concat(hours > 1 ? 'hrs' : 'hr');

        if (minutes > 0) {
          display += " ".concat(minutes, "min");
        }

        return display;
      }
    }),
    nameWithDuration: Ember.computed('name', 'durationDisplay', function () {
      return "".concat(this.get('name'), " (").concat(this.get('durationDisplay'), ")");
    }),
    formattedPrice: Ember.computed('price', function () {
      var thePrice = this.get('price');
      var decimalPrice = parseFloat(thePrice);

      if (decimalPrice % 1 === 0) {
        return parseInt(decimalPrice);
      } else {
        return decimalPrice.toFixed(2);
      }
    }),
    formattedIncrementalPrice: Ember.computed('incremental_price', function () {
      var thePrice = this.get('incremental_price');
      var decimalPrice = parseFloat(thePrice);

      if (decimalPrice % 1 === 0) {
        return parseInt(decimalPrice);
      } else {
        return decimalPrice.toFixed(2);
      }
    })
  });

  _exports.default = _default;
});