define("frontend/controllers/profile/integrations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    googleCalendarConnected: computed('currentUser.user.company_user_google_calendar_profiles.@each', function () {
      var _this = this;

      var profiles = this.get('currentUser.user.company_user_google_calendar_profiles');

      if (profiles) {
        return !!(profiles.filter(function (profile) {
          return profile.get('company.id') === _this.get('currentUser.user.company.id');
        }).length > 0);
      } else return false;
    }),
    actions: {
      goToGoogleCalendar: function goToGoogleCalendar() {
        localStorage.setItem("fromCompanyIntegrations", false);
        this.transitionToRoute('profile.integrations.google-calendar');
      }
    }
  });

  _exports.default = _default;
});