define("frontend/components/integrations/integration-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['integration-card'],
    description: null,
    isBeta: false,
    isEnabled: false,
    logoUri: null,
    onPress: null
  });

  _exports.default = _default;
});