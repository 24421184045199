define("frontend/components/flash-message-subscription-payment-failed", ["exports", "jquery", "frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    reactApp: inject.service(),
    currentUser: inject.service(),
    today: moment().format('YYYY-MM-DD'),
    flashMessage: inject.service(),
    monthText: computed('today', 'currentUser.company', function () {
      if (this.get('currentUser.user.company.subscription_plan') === 'monthly') {
        return moment(this.get('today'), 'YYYY-MM-DD').format('MMMM');
      } else {
        return 'annual';
      }
    }),
    lastDayOfMonth: Ember.computed('today', function () {
      return moment(this.get('today'), 'YYYY-MM-DD').endOf('month').format('MMMM D, YYYY');
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    yesText: 'manage billing',
    noText: 'maybe later',
    actions: {
      yes: function yes() {
        window.location = this.get('reactApp').companySubscriptionsUrl;
        this.send('dismissAlert');
      },
      no: function no() {
        this.send('dismissAlert');
      },
      dismissAlert: function dismissAlert() {
        var _this = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/users/").concat(this.get('currentUser.user.id'), "/dismiss_subscription_payment_failed_alert"),
          method: 'PUT'
        }).then(function () {
          Ember.getOwner(_this).lookup('controller:application').set('notification', '');
        });
      },
      openBeacon: function openBeacon() {
        // eslint-disable-next-line no-undef,new-cap
        Beacon('open');
      }
    }
  });

  _exports.default = _default;
});