define("frontend/routes/token-login-redirect", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Route.extend({
    session: inject.service(),
    accountSession: inject.service(),
    currentUser: inject.service(),
    reactApp: inject.service(),
    queryParams: {
      token: null,
      redirect_to: null,
      cid: null,
      hid: null,
      pid: null,
      iid: null,
      email_token: null,
      accept_token: null,
      appointmentId: null
    },
    model: function model(params) {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        this.redirectFromParams(params);
      } else {
        var store = this.get('session.store');
        return _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/users/token_login"),
          method: 'GET',
          data: {
            token: params.token
          }
        }).then(function (response) {
          sessionStorage.setItem('cancelRefreshOnCookieChange', true);
          store.clear().then(function () {
            if (response.redirect) {
              window.location.href = '/users/login';
            } else {
              store.persist(response.cookie).then(function () {
                if (!_this.get('accountSession').hasCompany()) {
                  if (['invoice', 'payment', 'report_card', 'appointments'].includes(params.redirect_to)) {
                    _this.get('accountSession').switchAccounts(0);
                  } else if (['payment-detail', 'invoice-detail'].includes(params.redirect_to)) {
                    var redirectHref = "/invoices-summary/".concat(params.hid, "/").concat(params.redirect_to, "/").concat(params.pid);

                    _this.get('accountSession').switchAccounts(params.cid, redirectHref);
                  } else if (['edit-draft-invoice'].includes(params.redirect_to)) {
                    var _redirectHref = "/invoices-summary/".concat(params.hid, "/invoice-detail/").concat(params.iid, "?editing=true");

                    _this.get('accountSession').switchAccounts(params.cid, _redirectHref);
                  } else if (['billing'].includes(params.redirect_to)) {
                    var _redirectHref2 = "/react/settings/company/subscriptions?historyTab=invoices";

                    _this.get('accountSession').switchAccounts(params.cid, _redirectHref2);
                  }
                }

                _this.redirectFromParams(params);
              });
            }
          });
        });
      }
    },
    redirectFromParams: function redirectFromParams(params) {
      switch (params.redirect_to) {
        case 'accept_client':
          window.location.href = "/accept-company/".concat(params.accept_token, "?email=").concat(params.email_token);
          break;

        case 'accept_staff':
          window.location.href = "/accept-staff/".concat(params.accept_token, "?email=").concat(params.email_token);
          break;

        case 'appointments':
          window.location.href = "/portal/appointments";
          break;

        case 'billing':
          window.location = this.get('reactApp').companySubscriptionsUrl;
          break;

        case 'edit-draft-invoice':
          window.location.href = "/invoices-summary/".concat(params.hid, "/invoice-detail/").concat(params.iid, "?editing=true");
          break;

        case 'invoice':
          window.location.href = "/portal/invoices-summary/company/".concat(params.cid);
          break;

        case 'payment':
          window.location.href = "/portal/invoices-summary/company/".concat(params.cid, "/payment-detail/").concat(params.pid, "?showAddTip=true");
          break;

        case 'payment-detail':
          window.location.href = "/invoices-summary/".concat(params.hid, "/payment-detail/").concat(params.pid);
          break;

        case 'report_card':
          window.location.href = "/portal/messages/company/".concat(params.cid, "?appointmentId=").concat(params.appointmentId, "&showReportCard=true");
          break;

        default:
          window.location.href = '/users/login';
      }
    }
  });

  _exports.default = _default;
});