define("frontend/templates/components/integrations/integration-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BcEEu7Ls",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[24,[\"onPress\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isBeta\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"beta-tag\"],[8],[0,\"\\n    \"],[1,[28,\"inline-svg\",[\"/assets/images/integration-logos/beta-tag.svg\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"icon\"],[8],[7,\"img\",true],[11,\"src\",[22,\"logoUri\"]],[8],[9],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n    \"],[1,[22,\"description\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"integration-status\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isEnabled\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\",true],[10,\"class\",\"status-icon\"],[10,\"src\",\"/assets/images/integration-logos/connected.png\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"requiresApproval\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\",true],[10,\"class\",\"status-icon\"],[10,\"src\",\"/assets/images/integration-logos/requires-approval.png\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"img\",true],[10,\"class\",\"status-icon\"],[10,\"src\",\"/assets/images/integration-logos/not-connected.png\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/integrations/integration-card.hbs"
    }
  });

  _exports.default = _default;
});