define("frontend/serializers/user", ["exports", "ember-data", "active-model-adapter", "frontend/mixins/country-serializer"], function (_exports, _emberData, _activeModelAdapter, _countrySerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _countrySerializer.default, {
    attrs: {
      phone_numbers: {
        serialize: 'records',
        deserialize: 'ids'
      },
      emails: {
        serialize: 'records',
        deserialize: 'ids'
      },
      addresses: {
        serialize: 'records',
        deserialize: 'ids'
      },
      company_user_google_calendar_profiles: {
        serialize: 'records',
        deserialize: 'ids'
      }
    }
  });

  _exports.default = _default;
});