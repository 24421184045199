define("frontend/templates/profile/integrations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w0Tvl8Nt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"integrations-settings\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"integrations-title\"],[8],[0,\"Integrations\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"integrations-list\"],[8],[0,\"\\n    \"],[1,[28,\"integrations/integration-card\",null,[[\"description\",\"logoUri\",\"isEnabled\",\"isBeta\",\"onPress\"],[\"Automatically sync appointments with your Google Calendar\",\"/assets/images/integration-logos/google-calendar.png\",[24,[\"googleCalendarConnected\"]],true,[28,\"action\",[[23,0,[]],\"goToGoogleCalendar\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/profile/integrations/index.hbs"
    }
  });

  _exports.default = _default;
});