define("frontend/controllers/companies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    saving: false,
    router: inject.service(),
    reactApp: inject.service(),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    gettingPaidRoute: computed.equal('currentRouteName', 'companies.getting-paid'),
    queryParams: ['backRoute'],
    backRoute: null,
    pricingRoute: computed.equal('currentRouteName', 'companies.pricing'),
    editCompaniesRoute: computed.equal('currentRouteName', 'companies.edit'),
    payingStaffRoute: computed.equal('currentRouteName', 'companies.paying-staff'),
    removeStaffRoute: computed.equal('currentRouteName', 'companies.remove-staff'),
    removeServiceRoute: computed.equal('currentRouteName', 'companies.remove-service'),
    categorySettingsRoute: computed.equal('currentRouteName', 'companies.category-settings'),
    currentUser: inject.service(),
    activityLogs: inject.service(),
    hasUnreadLogs: computed.alias('activityLogs.hasUnreadLogs'),
    reactDocumentsLink: computed.alias('reactApp.companyDocumentsUrl'),
    reactSubscriptionsLink: computed.alias('reactApp.companySubscriptionsUrl'),
    savingMessage: computed('overrideSavingMessage', function () {
      var message = this.get('overrideSavingMessage');

      if (message) {
        return message;
      } else {
        return 'Saving...';
      }
    }),
    removeRoute: computed('currentRouteName', function () {
      var currentRouteName = this.get('currentRouteName');
      return currentRouteName == 'companies.remove-service' || currentRouteName == 'companies.remove-staff';
    }),
    showIntegrationsTab: computed('model.quickbooksAccess', 'model.googleCalendarAccess', function () {
      return this.get('model.quickbooksAccess') || this.get('model.googleCalendarAccess');
    }),
    actions: {
      goBack: function goBack() {
        if (this.get('gettingPaidRoute')) {
          Ember.getOwner(this).lookup('route:companies.getting-paid').send('cancel', this.get('model'));
        } else {
          this.transitionToRoute('companies.edit', this.get('model.id'));
        }
      },
      uploadedPhoto: function uploadedPhoto(photo) {
        this.set('model.logo_url', photo);
      },
      clickHamburger: function clickHamburger() {
        Ember.run.scheduleOnce('afterRender', function () {
          $('body').toggleClass('push-active');
        });
      }
    }
  });

  _exports.default = _default;
});