define("frontend/models/pet", ["exports", "ember-data", "moment", "frontend/mixins/age-calculator"], function (_exports, _emberData, _moment, _ageCalculator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATUS = void 0;
  var computed = Ember.computed;
  var PET_PLACEHOLDER_PREFIX = 'assets/images/placeholders';
  var STATUS = {
    male: [{
      value: true,
      label: 'neutered'
    }, {
      value: false,
      label: 'intact'
    }],
    female: [{
      value: true,
      label: 'spayed'
    }, {
      value: false,
      label: 'intact'
    }]
  };
  _exports.STATUS = STATUS;

  var _default = _emberData.default.Model.extend(_ageCalculator.default, {
    modelName: 'pet',
    name: _emberData.default.attr('capitalized-string'),
    veterinarian_clinic: _emberData.default.belongsTo('veterinarian-clinic'),
    insurance: _emberData.default.belongsTo('insurance'),
    doctor: _emberData.default.belongsTo('doctor'),
    gender: _emberData.default.attr('string'),
    prescriptions: _emberData.default.attr('string'),
    allergies: _emberData.default.attr('string'),
    past_injuries: _emberData.default.attr('string'),
    medical_extra_notes: _emberData.default.attr('string'),
    vaccinations: _emberData.default.hasMany('vaccination'),
    pet_prescriptions: _emberData.default.hasMany('pet-prescription'),
    spayed_or_neutered: _emberData.default.attr(),
    birthday: _emberData.default.attr('string'),
    microchip_number: _emberData.default.attr('string'),
    rabies_tag_number: _emberData.default.attr('string'),
    internal_user_notes: _emberData.default.attr('string'),
    client_notes: _emberData.default.attr('string'),
    company_notes: _emberData.default.attr(),
    household: _emberData.default.belongsTo('household', {
      async: true
    }),
    emergency_contacts: _emberData.default.hasMany('emergency-contact'),
    avatar_url: _emberData.default.attr(),
    service_animal: _emberData.default.attr('boolean'),
    breed: _emberData.default.belongsTo('breed', {
      async: true
    }),
    pet_type_id: _emberData.default.attr('string', {
      defaultValue: '1'
    }),
    pet_type: _emberData.default.attr('string', {
      defaultValue: 'Dog'
    }),
    gets_along_with_dogs: _emberData.default.attr('string'),
    gets_along_with_cats: _emberData.default.attr('string'),
    gets_along_with_humans: _emberData.default.attr('string'),
    gets_along_with_extra_notes: _emberData.default.attr('string'),
    phobias: _emberData.default.attr('string'),
    potty_habits: _emberData.default.attr('string'),
    diet: _emberData.default.attr('string'),
    house_rules: _emberData.default.attr('string'),
    off_leash_notes: _emberData.default.attr('string'),
    commands: _emberData.default.attr('string'),
    translations: _emberData.default.attr('string'),
    behavioral_extra_notes: _emberData.default.attr('string'),
    statusLabel: computed('spayed_or_neutered', 'gender', function () {
      if (this.get('spayed_or_neutered') == null || this.get('gender') == null) {
        return null;
      }

      return STATUS[this.get('gender')].filterBy('value', this.get('spayed_or_neutered'))[0].label;
    }),
    // the default HTML input requires us to use YYYY-MM-DD format
    // unfourtantly we use MM/DD/YYYY everywhere else (including our DB)
    // this returns YYYY-MM-DD and saves MM/DD/YYYY
    birthdayTransform: computed('birthday', {
      set: function set(key, value) {
        if (!value) {
          this.set('birthday', null);
          return null;
        } else {
          this.set('birthday', (0, _moment.default)(value, 'YYYY-MM-DD').format('MM/DD/YYYY'));
          return (0, _moment.default)(this.get('birthday'), 'MM/DD/YYYY').format('YYYY-MM-DD');
        }
      },
      get: function get() {
        if (!this.get('birthday')) {
          return null;
        }

        return (0, _moment.default)(this.get('birthday')).format('YYYY-MM-DD');
      }
    }),
    isBirthday: computed('birthday', function () {
      var birthday = this.get('birthday');

      if (!birthday || (0, _moment.default)(birthday).format('MM-DD') != (0, _moment.default)().format('MM-DD')) {
        return false;
      } else {
        return true;
      }
    }),
    fullName: computed.alias('name'),
    getFirstName: computed.alias('name'),
    age: computed('birthday', function () {
      return this.calculateAge(this.get('birthday'));
    }),
    hasCompanyNotes: computed('company_notes.[]', function () {
      var companyNotes = this.get('company_notes');
      var hasNotes = false;
      companyNotes.forEach(function (notes) {
        if (notes.notes) {
          hasNotes = true;
        }
      });
      return hasNotes;
    }),
    hasNotes: computed('internal_user_notes', 'client_notes', function () {
      return Ember.isPresent(this.get('internal_user_notes')) || Ember.isPresent(this.get('client_notes'));
    }),
    avatarOrPlaceholder: computed('avatar_url', 'pet_type', function () {
      return this.get('avatar_url') || "".concat(PET_PLACEHOLDER_PREFIX, "/").concat(this.get('pet_type'), ".svg");
    }),
    emptyVet: computed.not('veterinarian_clinic.id'),
    hasVet: computed.not('emptyVet'),
    emptyInsurance: computed.not('insurance.id'),
    hasInsurance: computed.not('emptyInsurance'),
    hasEmergencyContacts: computed.gt('emergency_contacts.length', 0),
    hasVaccinations: computed.gt('vaccinations.length', 0),
    hasPrescriptions: computed.gt('pet_prescriptions.length', 0),
    hasMedical: computed.or('hasVet', 'hasEmergencyContacts', 'hasInsurance', 'hasVaccinations', 'hasPrescriptions', 'prescriptions', 'allergies', 'past_injuries', 'medical_extra_notes'),
    hasBehavioral: computed.or('gets_along_with_extra_notes', 'phobias', 'potty_habits', 'diet', 'house_rules', 'off_leash_notes', 'commands', 'translations', 'behavioral_extra_notes'),
    hasAnyInfo: computed.or('hasNotes', 'hasMedical', 'hasBehavioral')
  });

  _exports.default = _default;
});