define("frontend/components/card-on-file-reminder-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    type: null,
    freeTrialEndsDate: computed('currentUser.user.company.free_trial_ends', function () {
      return (0, _moment.default)(this.get('currentUser.user.company.free_trial_ends')).format('dddd, MMMM D');
    }),
    daysUntilFreeTrialEnds: computed('currentUser.user.company.free_trial_ends', function () {
      var freeTrialEnds = (0, _moment.default)(this.get('currentUser.user.company.free_trial_ends'));
      return freeTrialEnds.diff((0, _moment.default)(), 'days');
    }),
    trialHasEnded: computed.equal('reminderType', 'locked'),
    UI: computed('type', 'companyName', 'daysUntilFreeTrialEnds', 'userIsOwnerOrAdministrator', 'freeTrialEndsDate', function () {
      switch (this.get('type')) {
        case 'account-locked':
          return {
            emoji: "assets/images/emojis/emoji-face-without-mouth.svg",
            title: this.get('userIsOwnerOrAdministrator') ? "Account suspended" : "Your account is locked!"
          };

        case 'trial-locked':
          return {
            emoji: "assets/images/emojis/emoji-face-without-mouth.svg",
            title: "Uh oh, your trial has ended"
          };

        default:
          return {
            emoji: "assets/images/emojis/emoji-angel.svg",
            title: "Your free trial ends in ".concat(this.get('daysUntilFreeTrialEnds'), " day").concat(this.get('daysUntilFreeTrialEnds') > 1 ? "s" : "", "!")
          };
      }
    }),
    userIsOwnerOrAdministrator: computed('currentUser.user.role', function () {
      return this.get('currentUser.user.role') === 'Owner' || this.get('currentUser.user.role') === 'Administrator';
    }),
    companyName: computed('currentUser.user.company.name', function () {
      return this.get('currentUser.user.company.name');
    }),
    userHasOtherAccounts: computed('currentUser.user.has_client_accounts', 'currentUser.user.staff_companies', function () {
      var _this = this;

      if (this.get('currentUser.user.has_client_accounts')) {
        return true;
      } else if (this.get('currentUser.user.staff_companies').filter(function (c) {
        return c.id !== Number(_this.get('currentUser.user.company.id'));
      }).length > 0) {
        return true;
      }

      return false;
    }),
    actions: {
      onClose: function onClose(navigateToAccountSelect) {
        this.dismiss(navigateToAccountSelect);
      },
      addCard: function addCard() {
        this.goToBilling();
      }
    }
  });

  _exports.default = _default;
});