define("frontend/controllers/companies/integrations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    company: computed.alias('currentUser.user.company'),
    googleCalendarStatus: computed('currentUser.user.company.google_calendar_enabled', 'currentUser.user.company_user_google_calendar_profiles.@each', function () {
      var _this = this;

      var profiles = this.get('currentUser.user.company_user_google_calendar_profiles');
      var hasConnectedEmail = profiles === null || profiles === void 0 ? void 0 : profiles.filter(function (profile) {
        return profile.get('company.id') === _this.get('currentUser.user.company.id');
      }).map(function (profile) {
        return profile.get('email');
      })[0];
      var googleCalendarEnabled = this.get('currentUser.user.company.google_calendar_enabled');

      if (!googleCalendarEnabled) {
        return 'requires approval';
      } else if (googleCalendarEnabled && !hasConnectedEmail) {
        return 'not connected';
      } else {
        return 'connected';
      }
    }),
    googleCalendarRequiresApproval: computed('googleCalendarStatus', function () {
      return this.get('googleCalendarStatus') === 'requires approval';
    }),
    googleCalendarIsEnabled: computed('googleCalendarStatus', function () {
      return this.get('googleCalendarStatus') === 'connected';
    }),
    actions: {
      goToQuickbooks: function goToQuickbooks() {
        this.transitionToRoute('companies.integrations.quickbooks', this.get('currentUser.user.company.id'));
      },
      goToGoogleCalendar: function goToGoogleCalendar() {
        this.transitionToRoute('companies.integrations.google-calendar', this.get('currentUser.user.company.id'));
      }
    }
  });

  _exports.default = _default;
});