define("frontend/templates/companies/integrations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L2japbig",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"integrations-settings\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"integrations-title\"],[8],[0,\"Integrations\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"integration-list\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"company\",\"quickbooksAccess\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"integrations/integration-card\",null,[[\"description\",\"logoUri\",\"isEnabled\",\"isBeta\",\"onPress\"],[\"Automatically sync invoices and payments to QuickBooks Online\",\"/assets/images/integration-logos/quickbooks-logo.png\",[24,[\"company\",\"quickbooks_realm_id\"]],true,[28,\"action\",[[23,0,[]],\"goToQuickbooks\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"company\",\"googleCalendarAccess\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"integrations/integration-card\",null,[[\"description\",\"logoUri\",\"isEnabled\",\"requiresApproval\",\"isBeta\",\"onPress\"],[\"See PetPocketbook appointments in Google Calendar\",\"/assets/images/integration-logos/google-calendar.png\",[24,[\"googleCalendarIsEnabled\"]],[24,[\"googleCalendarRequiresApproval\"]],true,[28,\"action\",[[23,0,[]],\"goToGoogleCalendar\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/companies/integrations/index.hbs"
    }
  });

  _exports.default = _default;
});