define("frontend/components/tax-settings-modal", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var emailValidator = (0, _emberCpValidations.validator)('format', {
    regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'must be a valid email address'
  });
  var EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var Validations = (0, _emberCpValidations.buildValidations)({
    emailFor1099EDelivery: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), emailValidator],
    'company.street': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'company.city': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'company.state': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'company.zipcode': (0, _emberCpValidations.validator)('international-postal-code')
  });

  var _default = Ember.Component.extend(Validations, {
    countryInfo: inject.service(),
    flashMessage: inject.service(),
    company: null,
    close: null,
    emailFor1099EDelivery: computed.alias('company.email_for_1099_e_delivery'),
    emailValidation: [{
      message: '*must be a valid email',
      validate: function validate(inputValue) {
        return EMAIL_REGEX.test(inputValue) || !inputValue || inputValue.trim() === '';
      }
    }],
    // This is our stored value in the DB false by default
    consentsTo1099EDelivery: computed.alias('company.consents_to_1099_e_delivery'),
    // This is the intent, we default them to true
    willConsentTo1099EDelivery: true,
    emailFor1099EDeliveryInvalid: computed('emailFor1099EDelivery', function () {
      return !this.get('validations.attrs.emailFor1099EDelivery.isValid');
    }),
    didValidate: false,
    stateOptions: computed('countryInfo.states.[]', function () {
      return this.get('countryInfo.usStates');
    }),
    allValidationsValid: computed('validations.isTruelyValid', function () {
      return this.get('validations.isTruelyValid');
    }),
    showDisclosure: false,
    savingTaxSettings: false,
    showSafetyDismissButton: false,
    actions: {
      pressConfirmButton: function pressConfirmButton() {
        if (this.get('willConsentTo1099EDelivery')) {
          this.set('showDisclosure', true);
        } else {
          this.send('saveTaxSettings');
        }
      },
      saveTaxSettings: function saveTaxSettings() {
        var _this = this;

        this.set('showDisclosure', false);
        this.set('savingTaxSettings', true);
        var payload = {
          consents_to_1099_e_delivery: this.get('willConsentTo1099EDelivery'),
          email_for_1099_e_delivery: this.get('emailFor1099EDelivery'),
          street: this.get('company.street'),
          city: this.get('company.city'),
          state: this.get('company.state'),
          zipcode: this.get('company.zipcode')
        };
        jQuery.ajax({
          url: "/api/v2/companies/".concat(this.get('company.id'), "/update_tax_settings"),
          method: 'PATCH',
          data: payload
        }).then(function (response) {
          _this.get('close')();
        }).catch(function (error) {
          _this.get('close')();
        });
      },
      toggleWillConsentTo1099EDelivery: function toggleWillConsentTo1099EDelivery() {
        this.toggleProperty('willConsentTo1099EDelivery');
      },
      hideDisclosure: function hideDisclosure() {
        this.set('showDisclosure', false);
      },
      openHelpLink: function openHelpLink() {
        var link = 'https://help.petpocketbook.com/article/170-form-1099k-faqs';
        window.open(link, '_blank');
      }
    }
  });

  _exports.default = _default;
});