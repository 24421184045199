define("frontend/routes/edit-single-appointment", ["exports", "frontend/routes/base-route", "frontend/mixins/transition-back-route", "frontend/config/environment", "moment", "jquery"], function (_exports, _baseRoute, _transitionBackRoute, _environment, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_transitionBackRoute.default, {
    currentUser: Ember.inject.service(),
    queryParams: {
      ids: {
        replace: true
      },
      requestId: {
        replace: true
      }
    },
    wasUnassigned: false,
    model: function model(_ref) {
      var _this = this;

      var ids = _ref.ids,
          requestId = _ref.requestId;

      this._super.apply(this, arguments);

      var offeredServices = this.store.query('offered-service', {}, {
        reload: true
      });
      var careProviders = this.store.query('care-provider', {}, {
        reload: true
      });
      var modelHash = {
        offeredServices: offeredServices,
        careProviders: careProviders
      };

      if (requestId) {
        modelHash.appointmentRequest = this.store.findRecord('appointment-request', requestId);
      } else {
        modelHash.appointments = Ember.RSVP.Promise.all(ids.split(',').map(function (id) {
          return _this.store.findRecord('appointment', id, {
            reload: true
          });
        }));
      }

      return Ember.RSVP.hash(modelHash);
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (model.appointmentRequest) {
        var preferredStaff = model.appointmentRequest.get('household.preferred_staff.firstObject');
        var preferredStaffId = preferredStaff ? preferredStaff.get('user_id') : this.get('currentUser.user.id');
        var appointments = model.appointmentRequest.get('pets').map(function (pet) {
          if (!model.appointmentRequest.get('care_provider.id')) {
            _this2.set('wasUnassigned', true);
          } else {
            _this2.set('wasUnassigned', false);
          }

          var careProviderId = model.appointmentRequest.get('care_provider.id') || preferredStaffId;
          var care_provider = model.careProviders.filter(function (careProvider) {
            return careProvider.get('id') == careProviderId;
          }).get('firstObject');
          var add_on_services = model.appointmentRequest.get('add_on_services');
          var pet_add_on_services = add_on_services.filter(function (aos) {
            return aos.pets.mapBy('id').includes(parseInt(pet.get('id')));
          });
          pet_add_on_services = pet_add_on_services.map(function (add_on_service) {
            return _this2.store.createRecord('appointment-add-on-service', {
              offered_service: model.offeredServices.find(function (os) {
                return os.get('id') == add_on_service.offered_service.id;
              })
            });
          });
          return _this2.store.createRecord('appointment', {
            service_starts_at: model.appointmentRequest.get('starts_at'),
            time_frame_start_time: model.appointmentRequest.get('time_frame_start_time'),
            time_frame_end_time: model.appointmentRequest.get('time_frame_end_time'),
            offered_service: model.appointmentRequest.get('offered_service'),
            care_provider: care_provider,
            appointment_add_on_services: pet_add_on_services,
            set_by_custom_window: false,
            pet: pet
          });
        });
        model.appointments = appointments;
      }

      var firstAppointment = model.appointments.get('firstObject');

      var momentTime = _moment.default.parseZone(firstAppointment.get('service_starts_at'));

      var hour = momentTime.hour();
      var minute = momentTime.minute();

      var newTime = _moment.default.parseZone(firstAppointment.get('service_starts_at')).hour(hour).minute(minute).format();

      model.appointments.forEach(function (appt) {
        return appt.set('service_starts_at', newTime);
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('redirecting', false);
      controller.set('saving', false);
      controller.set('wasUnassigned', this.get('wasUnassigned'));
      var firstAppointment = model.appointments.get('firstObject');

      if (firstAppointment) {
        controller.set('originalCareProviderId', firstAppointment.get('care_provider.id'));
        controller.set('originalStartsAt', firstAppointment.get('service_starts_at'));
        controller.set('originalOfferedServiceId', firstAppointment.get('offered_service.id'));
      }

      model.appointments.forEach(function (appointment) {
        appointment.set('selectedCareProviderId', appointment.get('care_provider.id'));
        appointment.set('selectedOfferedServiceId', appointment.get('offered_service.id'));
        appointment.set('selectedPosition', appointment.get('grouped_appointment.position'));
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('requestId', null);
        controller.set('ids', null);
      }
    },
    updateAppointments: function updateAppointments(appointments) {
      var firstAppointment = appointments.get('firstObject');
      var care_provider_id = firstAppointment.get('selectedCareProviderId');
      var offered_service_id = firstAppointment.get('selectedOfferedServiceId');
      var time_frame_start_time = firstAppointment.get('time_frame_start_time');
      var time_frame_end_time = firstAppointment.get('time_frame_end_time');
      var set_by_custom_window = firstAppointment.get('set_by_custom_window');
      var position = firstAppointment.get('selectedPosition');
      var special_instructions = {};
      appointments.forEach(function (appt) {
        var id = appt.get('id');
        special_instructions[id] = appt.get('special_instructions');
      });

      var date = _moment.default.parseZone(firstAppointment.get('service_starts_at')).format('YYYY-MM-DD');

      var time = _moment.default.parseZone(firstAppointment.get('service_starts_at')).format('h:mm A');

      var add_on_services = [];
      appointments.forEach(function (appt) {
        appt.get('appointment_add_on_services').forEach(function (addOnService) {
          if (!addOnService.isDeleted) {
            add_on_services.push({
              offered_service_id: addOnService.get('offered_service.id'),
              pet_id: appt.get('pet.id')
            });
          }
        });
      });
      var scheduling = {
        care_provider_id: care_provider_id,
        offered_service_id: offered_service_id,
        date: date,
        time: time,
        time_frame_start_time: time_frame_start_time,
        time_frame_end_time: time_frame_end_time,
        set_by_custom_window: set_by_custom_window,
        position: position,
        add_on_services: add_on_services
      };
      var ids = appointments.map(function (appt) {
        return appt.get('id');
      });
      var url = "".concat(_environment.default.APP.host, "/api/v1/appointment_schedulings");
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          url: url,
          method: 'PUT',
          data: {
            ids: ids,
            appointment_scheduling: scheduling,
            special_instructions: special_instructions
          }
        }).then(function (response) {
          resolve(response);
        }).fail(function (e) {
          reject(e);
        });
      });
    },
    actions: {
      cancel: function cancel(appointments) {
        var backRoute = this.get('controller.backRoute');
        var backHouseholdId = this.get('controller.backHouseholdId');
        var backId = this.get('controller.backId');
        this.set('controller.redirecting', true);
        this.transitionBack(backRoute, backHouseholdId, backId);
        appointments.forEach(function (appt) {
          var addOns = appt.get('appointment_add_on_services').map(function (aos) {
            return aos;
          });

          for (var i = 0; i < addOns.length; i++) {
            addOns.objectAt(i).rollbackAttributes();
          }

          appt.rollbackAttributes();
        });
      },
      remove: function remove(appointments) {
        var _this3 = this;

        var controller = this.get('controller');
        var backRoute = this.get('controller.backRoute');
        var backHouseholdId = this.get('controller.backHouseholdId');
        var backId = this.get('controller.backId');
        var backCareProviderId = this.get('controller.backCareProviderId');
        var backDate = this.get('controller.backDate');

        if (controller.get('saving')) {
          return;
        }

        controller.set('saving', true);
        var apptPromises = appointments.map(function (appt) {
          appt.destroyRecord({
            adapterOptions: {
              method: 'remove button in edit single appointment'
            }
          });
        });
        Ember.RSVP.Promise.all(apptPromises).then(function () {
          if (backRoute == 'schedule.index') {
            return window.location = "/schedule?care_provider_id=".concat(backCareProviderId, "&date=").concat(backDate);
          } else if (backRoute == 'schedule.calendar') {
            return window.location = "/schedule/calendar?care_provider_id=".concat(backCareProviderId, "&date=").concat(backDate, "&schedule_type=calendar");
          } else {
            return _this3.transitionBack(backRoute, backHouseholdId, backId);
          }
        }).catch(function () {
          appointments.forEach(function (appt) {
            return appt.rollbackAttributes();
          });
          return;
        }).finally(function () {
          controller.set('saving', false);
        });
      },
      save: function save(appointments, position) {
        var _this4 = this;

        var controller = Ember.getOwner(this).lookup('controller:edit-single-appointment');
        var backRoute = this.get('controller.backRoute');
        var backHouseholdId = this.get('controller.backHouseholdId');
        var backId = this.get('controller.backId');

        if (controller.get('saving')) {
          return;
        }

        controller.set('saving', true);
        this.updateAppointments(appointments, position).then(function () {
          appointments.forEach(function (appointment) {
            var addOns = appointment.get('appointment_add_on_services');

            for (var i = 0; i < addOns.length; i++) {
              addOns.objectAt(i).rollbackAttributes();
            }

            return appointment.reload();
          });
          return _this4.transitionBack(backRoute, backHouseholdId, backId);
        });
      },
      decline: function decline(appointmentRequest) {
        var _this5 = this;

        var controller = Ember.getOwner(this).lookup('controller:edit-single-appointment');
        var backRoute = this.get('controller.backRoute');
        var backHouseholdId = this.get('controller.backHouseholdId');
        var backId = this.get('controller.backId');

        if (controller.get('saving')) {
          return;
        }

        controller.set('saving', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests/").concat(appointmentRequest.get('id'), "/decline"),
          method: 'POST',
          data: {
            declined_note: appointmentRequest.get('declined_note')
          }
        }).then(function () {
          appointmentRequest.set('declined_at', (0, _moment.default)());
          return _this5.transitionBack(backRoute, backHouseholdId, backId);
        });
      },
      approve: function approve(appointmentRequest, appointments) {
        var _this6 = this;

        var controller = Ember.getOwner(this).lookup('controller:edit-single-appointment');
        var backRoute = this.get('controller.backRoute');
        var backHouseholdId = this.get('controller.backHouseholdId');
        var backId = this.get('controller.backId');

        if (controller.get('saving')) {
          return;
        }

        var appt = appointments.get('firstObject');
        var care_provider_id = appt.get('selectedCareProviderId');
        var offered_service_id = appt.get('selectedOfferedServiceId');
        var time_frame_start_time = appt.get('time_frame_start_time');
        var time_frame_end_time = appt.get('time_frame_end_time');
        var set_by_custom_window = appt.get('set_by_custom_window');
        var date_time = appt.get('service_starts_at');
        var special_instructions = appointmentRequest.get('special_instructions');
        var position = appt.get('selectedPosition');
        var add_on_services = {};
        appointments.forEach(function (appt) {
          appt.get('appointment_add_on_services').forEach(function (add_on_service) {
            if (!add_on_services[add_on_service.get('offered_service.id')]) {
              add_on_services[add_on_service.get('offered_service.id')] = {
                offered_service_id: add_on_service.get('offered_service.id'),
                pet_ids: []
              };
            }

            add_on_services[add_on_service.get('offered_service.id')].pet_ids.push(appt.get('pet.id'));
          });
        });
        add_on_services = Object.values(add_on_services);
        controller.set('saving', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests/").concat(appointmentRequest.get('id'), "/approve"),
          method: 'POST',
          data: {
            care_provider_id: care_provider_id,
            offered_service_id: offered_service_id,
            time_frame_start_time: time_frame_start_time,
            time_frame_end_time: time_frame_end_time,
            set_by_custom_window: set_by_custom_window,
            date_time: date_time,
            special_instructions: special_instructions,
            position: position,
            add_on_services: add_on_services
          }
        }).then(function () {
          appointmentRequest.set('approved_at', (0, _moment.default)());
          return _this6.transitionBack(backRoute, backHouseholdId, backId);
        });
      },
      sendRequestAppointmentToStaff: function sendRequestAppointmentToStaff(appointments, requestId) {
        var _this7 = this;

        var controller = Ember.getOwner(this).lookup('controller:edit-single-appointment');
        var backRoute = this.get('controller.backRoute');
        var backHouseholdId = this.get('controller.backHouseholdId');
        var backId = this.get('controller.backId');

        if (controller.get('saving')) {
          return;
        }

        controller.set('saving', true);
        var appt = appointments.get('firstObject');
        var appointments_to_modify = appointments.map(function (appt) {
          return appt.get('id');
        });
        var pet_ids = appointments.map(function (appt) {
          return appt.get('pet.id');
        });
        var care_provider_id = appt.get('selectedCareProviderId');
        var offered_service_id = appt.get('selectedOfferedServiceId');
        var time_frame_start_time = appt.get('time_frame_start_time');
        var time_frame_end_time = appt.get('time_frame_end_time');
        var date_time = appt.get('service_starts_at');

        var date = _moment.default.parseZone(date_time).format('YYYY-MM-DD');

        var time_frame = {
          time: _moment.default.parseZone(date_time).format('H:mmA'),
          start_time: time_frame_start_time,
          end_time: time_frame_end_time
        };
        var special_instructions = appt.get('special_instructions');
        var add_on_services = {};
        appointments.forEach(function (appt) {
          appt.get('appointment_add_on_services').forEach(function (add_on_service) {
            if (!add_on_services[add_on_service.get('offered_service.id')]) {
              add_on_services[add_on_service.get('offered_service.id')] = {
                offered_service_id: add_on_service.get('offered_service.id'),
                pet_ids: []
              };
            }

            add_on_services[add_on_service.get('offered_service.id')].pet_ids.push(appt.get('pet.id'));
          });
        });
        add_on_services = Object.values(add_on_services);
        var details = {
          auto_approve: false,
          request_to_modify: requestId,
          business_request: true,
          appointments_to_modify: appointments_to_modify,
          special_instructions: special_instructions,
          appointments: [{
            pet_ids: pet_ids,
            offered_service_id: offered_service_id,
            care_provider_id: care_provider_id,
            date: date,
            time_frame: time_frame,
            add_on_services: add_on_services
          }]
        };

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests"),
          method: 'POST',
          data: {
            details: details
          }
        }).then(function () {
          _this7.set('saving', false);

          return _this7.transitionBack(backRoute, backHouseholdId, backId);
        }).catch(function (e) {
          if (e.message != 'TransitionAborted') {
            _this7.get('errorHandler').handle(e);

            _this7.set('saving', false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});