define("frontend/controllers/application", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Controller.extend({
    phoneTypes: [{
      name: 'Mobile',
      id: 1
    }, {
      name: 'Work',
      id: 2
    }, {
      name: 'Home',
      id: 3
    }],
    timeZones: ['Eastern Time (US & Canada)', 'Central Time (US & Canada)', 'Mountain Time (US & Canada)', 'Pacific Time (US & Canada)', 'Alaska', 'Hawaii'],
    type: 'success',
    showAcceptTOSModal: false,
    showTOSDeclined: false,
    flashMessage: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    inboxHasUnreadMessages: Ember.inject.service(),
    hasUnreadMessages: Ember.computed.alias('inboxHasUnreadMessages.hasUnreadMessages'),
    onIndex: computed.equal('currentRouteName', 'index'),
    onMessagesTab: computed.equal('currentRouteName', 'portal.messages.company'),
    onGettingPaidPage: computed.equal('currentRouteName', 'companies.getting-paid'),
    company: computed.alias('currentUser.user.company'),
    userIsOwner: computed.alias('currentUser.user.isOwner'),
    showBeacons: computed('currentUser.user', 'currentUser.user.isPetParent', 'onMessagesTab', function () {
      return !this.get('currentUser.user') || this.get('currentUser.user.isPetParent') && !this.get('onMessagesTab');
    }),
    showEpaymentsReminderModal: computed('currentUser.user.showEpaymentsReminder', 'onGettingPaidPage', function () {
      if (this.get('onGettingPaidPage')) {
        return null;
      }

      return this.get('currentUser.user.showEpaymentsReminder');
    }),
    showHolidayUpdateReminderModal: computed('currentUser.user.showHolidayUpdateReminder', 'onGettingPaidPage', function () {
      return this.get('currentUser.user.showHolidayUpdateReminder');
    }),
    showTaxSettingsModal: computed('company.last_update_to_1099_e_delivery', 'company.country_iso_code', 'company.has_stripe_account', function () {
      // Modal will be show to the owner of US companies starting Jan 15th each year if they have a stripe account
      // They can't dismiss - only save, which will update the last updated timestamp
      var countryCode = this.get('currentUser.user.company.country_iso_code');
      var todayIsBeforeJan15 = (0, _moment.default)().isBefore((0, _moment.default)([(0, _moment.default)().year(), 0, 15]));
      var userIsOwner = this.get('userIsOwner');
      var hasStripeAccount = this.get('company.has_stripe_account');
      if (countryCode !== 'US' || !userIsOwner || todayIsBeforeJan15 || !hasStripeAccount) return false;
      var lastUpdated = this.get('company.last_update_to_1099_e_delivery');
      var updatedThisYear = (0, _moment.default)(lastUpdated).isSame((0, _moment.default)(), 'year');
      return updatedThisYear ? false : true;
    }),
    showSubscriptionPaymentFailedBanner: observer('currentUser.user.company.current_owed_balance', 'currentUser.user.role', 'currentUser.user.user_dismissed_messages.[]', 'showCardOnFileReminder', function () {
      var userRole = this.get('currentUser.user.role');

      if (this.get('currentUser.user.company.current_owed_balance') > 0 && (userRole === 'Owner' || userRole === 'Administrator') && !this.get('currentUser.user.user_dismissed_messages').includes('subscription-payment-failed') && this.get('showCardOnFileReminder') === null) {
        this.get('flashMessage').sendFlashMessage(null, 'subscription-payment-failed');
      }
    }),
    showCardOnFileReminder: computed('currentUser.user.showCardOnFileReminder', 'currentUser.user.company.free_trial_ends', function () {
      if (this.get('currentRouteName') === 'account-select') {
        return null;
      }

      if (this.get('currentUser.user.showCardOnFileReminder') && this.get('currentUser.user.company.free_trial_ends')) {
        return this.get('currentUser.user.showCardOnFileReminder');
      } else {
        return null;
      }
    }),
    dismissHolidaysModal: function dismissHolidaysModal(completed, callback) {
      var _this = this;

      _jquery.default.ajax({
        url: "/api/v2/users/".concat(this.get('currentUser.user.id'), "/dismiss_holidays_reminder"),
        method: 'PUT',
        data: {
          completed: completed
        }
      }).done(function () {
        _this.set('currentUser.user.showHolidayUpdateReminder', false);

        if (callback) {
          callback();
        }
      }).fail(function () {
        _this.get('flashMessage').error('Unexpected error dismissing modal. Please try again');
      });
    },
    goToCompanyPolicies: function goToCompanyPolicies() {
      this.transitionToRoute('companies.category-settings', this.get('currentUser.user.company.id'), 'policies', {
        queryParams: {
          showHolidayPolicyModal: true,
          holidayPolicyModalYear: 2025
        }
      });
    },
    actions: {
      closeNotification: function closeNotification() {
        this.set('notification', null);
      },
      reloadModel: function reloadModel(retryTransition) {
        this.send('reload', retryTransition);
      },
      refresh: function refresh() {
        window.location.reload();
      },
      notify: function notify(options) {
        this.set('yes', options.yes);
        this.set('no', options.no);
        this.set('notification', options);
      },
      yes: function yes() {
        this.get('yes')();
        this.set('notification', null);
      },
      no: function no() {
        this.get('no')();
        this.set('notification', null);
      },
      declineTOS: function declineTOS() {
        this.set('showTOSDeclined', true);
      },
      acceptTOS: function acceptTOS() {
        var _this2 = this;

        _jquery.default.ajax({
          url: "/api/v1/users/".concat(this.get('currentUser.user.id'), "/accept_tos"),
          method: 'PUT'
        }).done(function () {
          _this2.set('showAcceptTOSModal', false);

          _this2.set('showTOSDeclined', false);

          _this2.set('currentUser.user.accept_new_tos', false);
        }).fail(function () {
          _this2.get('flashMessage').error('Unexpected error updating terms of service acceptance. Please try again');
        });
      },
      openBeacon: function openBeacon() {
        // eslint-disable-next-line no-undef,new-cap
        Beacon('open');
      },
      goToPlanAndBilling: function goToPlanAndBilling() {
        this.send('dismissCardOnFileReminderModal', false);
        this.send('redirectToPlanAndBilling');
      },
      goToClientBilling: function goToClientBilling() {
        this.transitionToRoute('companies.getting-paid', this.get('currentUser.user.company'));
      },
      goToPolicies: function goToPolicies() {
        this.dismissHolidaysModal(true, this.goToCompanyPolicies.bind(this));
      },
      permenantlyDismissModal: function permenantlyDismissModal() {
        this.dismissHolidaysModal(true, null);
      },
      dismissHolidaysReminder: function dismissHolidaysReminder() {
        this.dismissHolidaysModal(false, null);
      },
      dismissEpaymentsReminder: function dismissEpaymentsReminder() {
        var _this3 = this;

        _jquery.default.ajax({
          url: "/api/v2/users/".concat(this.get('currentUser.user.id'), "/dismiss_epayments_reminder"),
          method: 'PUT'
        }).done(function () {
          _this3.set('currentUser.user.showEpaymentsReminder', false);
        }).fail(function () {
          _this3.get('flashMessage').error('Unexpected error dismissing modal. Please try again');
        });
      },
      dismissTaxSettingsModal: function dismissTaxSettingsModal() {
        this.set('showTaxSettingsModal', false);
      },
      dismissCardOnFileReminderModal: function dismissCardOnFileReminderModal(goToAccountSelect) {
        var _this4 = this;

        if (goToAccountSelect) {
          this.set('currentUser.user.showCardOnFileReminder', null);
          this.transitionToRoute('account-select');
        } else {
          _jquery.default.ajax({
            url: "/api/v2/users/".concat(this.get('currentUser.user.id'), "/dismiss_card_on_file_reminder"),
            method: 'PUT'
          }).done(function () {
            _this4.set('currentUser.user.showCardOnFileReminder', null);
          }).fail(function () {
            _this4.get('flashMessage').error('Unexpected error dismissing modal. Please try again');
          });
        }
      }
    }
  });

  _exports.default = _default;
});