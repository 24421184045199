define("frontend/components/add-tip-modal", ["exports", "frontend/config/environment", "ember-cp-validations", "jquery"], function (_exports, _environment, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'amountToPay': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })],
    'amountToTip': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    flashMessage: inject.service(),
    store: inject.service(),
    amountToPay: 0,
    amountError: false,
    tipRequiredError: false,
    customTipError: false,
    company: null,
    payment: null,
    tipMethod: '15 percent',
    serviceFeePercentage: 0.05,
    tipAlreadyLeft: computed('payment.tip_amount', function () {
      return this.get('payment.tip_amount') > 0;
    }),
    existingTipPaymentDate: computed('payment', function () {
      var tipDate = moment(this.get('payment.tip_created_at'));
      var today = moment().startOf('day');
      var yesterday = moment().subtract(1, 'days').startOf('day');

      if (tipDate.isSame(today, 'd')) {
        return "today";
      } else if (tipDate.isSame(yesterday, 'd')) {
        return "yesterday";
      } else {
        var daysAgo = today.diff(tipDate, 'days');
        return "".concat(daysAgo, " days ago");
      }
    }),
    savedCardInfo: computed('paymentMethods.card', function () {
      var customer_info = this.get('paymentMethods');

      if (customer_info.get('card')) {
        return {
          brand: customer_info.get('card.brand'),
          last_four: customer_info.get('card.last_four')
        };
      } else {
        return null;
      }
    }),
    savedBankAccountInfo: computed('paymentMethods.bank_account', function () {
      var customer_info = this.get('paymentMethods');

      if (customer_info.get('bank_account')) {
        return {
          brand: customer_info.get('bank_account.bank_name'),
          last_four: customer_info.get('bank_account.last_four'),
          verified: customer_info.get('bank_account.verified')
        };
      } else {
        return null;
      }
    }),
    showSavedCard: computed('savedCardInfo', 'paymentMethods.default', function () {
      return this.get('paymentMethods.default') === 'card' && this.get('savedCardInfo');
    }),
    showSavedBankAccount: computed('savedBankAccountInfo', 'paymentMethods.default', function () {
      return this.get('paymentMethods.default') === 'bank_account' && this.get('savedBankAccountInfo');
    }),
    showVerifyBankModal: computed('showSavedBankAccount', function () {
      if (this.get('showSavedBankAccount')) {
        return !this.get('savedBankAccountInfo.verified');
      }

      return false;
    }),
    paymentButtonDisabled: computed('paymentProccessing', 'showSavedCard', 'showSavedBankAccount', function () {
      if (this.get('paymentProccessing')) {
        return true;
      }

      if (!this.get('showSavedCard') && !this.get('showSavedBankAccount')) {
        return true;
      }

      return false;
    }),
    tenPercentTip: computed('payment.amount', function () {
      var amount = this.get('payment.amount');
      return parseFloat(amount) * 0.1;
    }),
    fifteenPercentTip: computed('payment.amount', function () {
      var amount = this.get('payment.amount');
      return parseFloat(amount) * 0.15;
    }),
    twentyPercentTip: computed('payment.amount', function () {
      var amount = this.get('payment.amount');
      return parseFloat(amount) * 0.2;
    }),
    amountToTip: computed('tipMethod', 'tenPercentTip', 'fifteenPercentTip', 'twentyPercentTip', 'customTipAmount', function () {
      var method = this.get('tipMethod');

      if (method == '10 percent') {
        return this.get('tenPercentTip');
      } else if (method == '15 percent') {
        return this.get('fifteenPercentTip');
      } else if (method == '20 percent') {
        return this.get('twentyPercentTip');
      } else if (method == 'custom') {
        return this.get('customTipAmount');
      } else {
        return 0;
      }
    }),
    serviceFeeAmount: computed('company.charge_service_fee', 'company.pet_parent_service_fee_cap', 'company.service_fee_split_percentage', 'amountToTip', function () {
      if (!this.get('company.charge_service_fee')) {
        return 0;
      } else {
        var fee_cap = this.get('company.pet_parent_service_fee_cap');
        var amount = parseFloat(this.get('amountToTip')) || 0;
        var fee = amount * parseFloat(this.get('serviceFeePercentage'));

        if (this.get('company.service_fee_split_percentage')) {
          fee *= parseFloat(this.get('company.service_fee_split_percentage'));
        }

        if (fee > 0 && fee < 1 && !this.get('company.service_fee_split_percentage')) {
          fee = 1;
        }

        if (fee_cap != null && fee_cap >= 0 && fee > fee_cap) {
          return fee_cap;
        } else {
          return fee;
        }
      }
    }),
    uncappedServiceFeeAmount: computed('company.charge_service_fee', 'amountToTip', function () {
      if (!this.get('company.charge_service_fee')) {
        return 0;
      } else {
        var amount = parseFloat(this.get('amountToTip')) || 0;
        amount *= parseFloat(this.get('serviceFeePercentage'));
        return Math.max(amount, 1);
      }
    }),
    serviceFeeTooltipText: computed('company.pet_parent_service_fee_cap', 'company.service_fee_split_percentage', function () {
      var fee_cap = this.get('company.pet_parent_service_fee_cap');
      var feePercentage = 5;
      var coverText = '';

      if (this.get('company.service_fee_split_percentage')) {
        feePercentage *= this.get('company.service_fee_split_percentage');
        coverText += "".concat(feePercentage, "%");
      }

      if (fee_cap != null && fee_cap > 0) {
        coverText += coverText === '' ? "$".concat(fee_cap) : " or $".concat(fee_cap, ", whichever is less");
        return "This non-refundable 5% fee (minimum $1.00) helps us cover operating costs. With ".concat(this.get('company.displayName'), ", you\u2019ll never pay more than ").concat(coverText, ".");
      } else if (fee_cap == 0) {
        return "This non-refundable 5% fee (minimum $1.00) helps us cover operating costs. With ".concat(this.get('company.displayName'), ", this fee is waived");
      } else {
        if (coverText !== '') {
          return "This non-refundable 5% fee (minimum $1.00) helps us cover operating costs. With ".concat(this.get('company.displayName'), ", you\u2019ll never pay more than ").concat(coverText, ".");
        } else {
          return "This non-refundable 5% fee (minimum $1.00) helps us cover operating costs.";
        }
      }
    }),
    totalAmountToPay: computed('amountToTip', 'serviceFeeAmount', function () {
      return (parseFloat(this.get('amountToTip')) || 0) + (parseFloat(this.get('serviceFeeAmount')) || 0);
    }),
    getDecimalPlaces: function getDecimalPlaces(number) {
      return String(number).replace('.', '').length - parseFloat(number).toFixed().length;
    },
    actions: {
      onClose: function onClose() {
        if (this.get('paymentProccessing')) {
          return;
        }

        this.get('onClose')();
      },
      makePayment: function makePayment() {
        var _this = this;

        this.set('paymentProccessing', true);
        this.set('customTipError', false);
        this.set('tipRequiredError', false);
        this.set('amountError', false);
        var amountToTip = this.get('amountToTip');

        if (amountToTip < 0) {
          this.set('customTipError', true);
          this.set('paymentProccessing', false);
          return;
        }

        if (!this.get('tipMethod')) {
          this.set('tipRequiredError', true);
          this.set('paymentProccessing', false);
          return;
        }

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/invoices/client_portal_additional_tip"),
          method: 'POST',
          data: {
            amount_to_tip: amountToTip,
            company_id: this.get('company.id'),
            invoice_payment_id: this.get('payment.id')
          }
        }).done(function (response) {
          _this.get('store').findRecord('invoice-payment', _this.get('payment.id')).then(function () {
            _this.set('paymentProccessing', false);

            _this.get('madePayment')(response.balance, parseFloat(amountToTip), true);
          });
        }).fail(function (data) {
          if (data && data.responseText) {
            var parsed = JSON.parse(data.responseText);

            if (parsed.message) {
              _this.get('flashMessage').error(parsed.message);
            } else {
              _this.get('flashMessage').error('Unexpected error adding tip');
            }
          } else {
            _this.get('flashMessage').error('Unexpected error adding tip');
          }

          _this.set('paymentProccessing', false);
        });
      },
      changePaymentMethod: function changePaymentMethod() {
        this.get('changePaymentMethod')();
      },
      redirectToMicrodeposits: function redirectToMicrodeposits() {
        this.get('redirectToMicrodeposits')();
      },
      selectTip: function selectTip(method) {
        this.set('tipMethod', method);

        if (method == 'custom') {
          var amountToTip = this.get('amountToTip');

          if (amountToTip) {
            this.set('customTipAmount', parseFloat(this.get('amountToTip')).toFixed(2));
          } else {
            this.set('customTipAmount', null);
          }

          Ember.run.scheduleOnce('afterRender', function () {
            $('.custom-tip-row input').focus();
          });
        }

        this.set('customTipError', false);
      }
    }
  });

  _exports.default = _default;
});